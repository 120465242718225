const colors = {
  green: "#789f3f",
  red: "#be4f38",
  brightRed: "#eb4928",
  blue: "#2e3192",
  lightGreen: "#789f3f50",
  lighterGreen: "#86AD4D3D",
  white: "#dad9d8",
};

export default colors;
